.form {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 30px;
}

.input {
	margin-top: 20px;
	background-color: #d7edf6;
	border: none;
	padding: 10px;
	border-radius: 10px 0px 0px 10px;
	height: 50px;
	width: 260px;
}

.input:focus {
	border: none;
	outline: none;
}

.button {
	margin-top: 60px;
	color: white;
	width: 300px;
	background-color: #025072;
	border: none;
}

.button:hover,
.button:focus {
	border: none;
	outline: none;
	background-color: #e7cf49;
}
